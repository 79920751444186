import React, {useRef, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {

  const [hasScrolled, setHasScrolled] = useState(false);
  const navigate = useNavigate();



  return (
    <>
        <nav className={
          hasScrolled ? 'nav-bar scrolled' : 'nav-bar'
        }>
      
                <div className='nav-left'>
                <img src={'/assets/schoolsenselogo.png'} alt='logo' />
                <ul>
                    <li><a href='/#'>Home</a></li>
                    <li><a href='#about-us'>About Us</a></li>
                    <li><a href='mailto:support@schoolsensefundraising.com'>Contact Us</a></li>
                    <li><a href='/app/onboarding'>I'm a Student</a></li>
                   
                </ul>
                </div>
                <button onClick={
                  () => navigate('/app')
                } className='btn nav-btn'>Campaign Dashboard</button>
              
        
        </nav>
    </>
  );

}

export default Navbar;